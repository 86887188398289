import React from 'react'

const Instagram = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30">
    <path fill={fill} d="M0 6.23v17.54A6.23 6.23 0 0 0 6.23 30h17.54A6.23 6.23 0 0 0 30 23.77V6.23A6.23 6.23 0 0 0 23.77 0H6.23A6.23 6.23 0 0 0 0 6.23zM18.18 23.5A9.1 9.1 0 0 1 6.5 11.82a8.6 8.6 0 0 1 5.32-5.32A9.1 9.1 0 0 1 23.5 18.18a8.6 8.6 0 0 1-5.32 5.32zM25.4 5.93a1.17 1.17 0 0 1-2.04.55.7.7 0 0 1-.07-.08 1.18 1.18 0 0 1-.05-1.24 1.17 1.17 0 0 1 1.6-.43 1.17 1.17 0 0 1 .57 1.1.72.72 0 0 1 0 .1zm0 0" />
    <path fill={fill} d="M15 7.7a7.3 7.3 0 1 0 7.3 7.3A7.3 7.3 0 0 0 15 7.7zm0 0" />
  </svg>
)

export default Instagram
