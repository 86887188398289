export default {
  PRIMARY: '#6140FF',
  SECONDARY: '#30F2D4',
  LIGHTER: '#FFF',
  MODULE_BACKGROUND: '#FFF',
  ABOUT_PRIMARY: '#253752',
  ABOUT_SECONDARY: '#30F2D4',
  ABOUT_SUPPORT: '#6140FF',
  DARK_LIGHT: '#F6F7F9',

  // hamburger
  DEFAULT_LINE: '#FFF',

  // text
  PRIMARY_TEXT: '#30F2D4',
  SECONDARY_TEXT: '#FFF',

  // module_colors
  HELLA_PRIMARY: '#00C691',
  HELLA_SECONDARY: '#FFF28B',
  BICYCLE_PRIMARY: '#6140FF',
  BICYCLE_SECONDARY: '#69DEBC',
  BICYCLE_SUPPORT: '#BEF7FF',
  SURF_PRIMARY: '#2AD9B1',
  SURF_SECONDARY: '#6140FF',
  SURF_SUPPORT: '#AEFFD6',
  HELLA_FUNNER_PRIMARY: '#6140FF',
  HELLA_FUNNER_SECONDARY: '#F26941',
  HELLA_FUNNER_SUPPORT: '#FFE848',
  INTRMODL_PRIMARY: '#196FBC',
  INTRMODL_SECONDARY: '#A2F4FC',
  INTRMODL_SUPPORT: '#242D53',
}
