import React from 'react'
import {
  Container,
  Title,
  Content,
  ContentTitle,
  ContentDescription,
  ContentPreview,
  Wrapper,
} from './styles'
import IMAGES from '../../constants/images'

const ProjectIntrmodl = () => (
  <Container>
    <Title>The project</Title>

    <Content>
      <Wrapper>
        <ContentTitle>Mobile app</ContentTitle>
        <ContentDescription>
        Inspired by ride-hailing apps like Uber and Lyft, Brandon Stewart, myself and the GNAR team designed a solution that uses GPS coordinates streamed from Android tablets in each vehicle. Their new platform, Intrmodl, turns the vehicles themselves into connected IoT devices that could send real-time data to a central platform for processing and analytics. The driver app not only tracks its vehicle’s location in the yard across time, but also logs usage stats, like fuel level and engine hours, and travel paths and duration, as well as vehicle inspection details.

        </ContentDescription>
      </Wrapper>
      <ContentPreview src={IMAGES.PROJECT_INTRMODL_APP} alt="App preview" />
    </Content>

    <Content>
      <ContentPreview
        src={IMAGES.PROJECT_INTRMODL_YARD_MAP}
        alt="Web preview"
        small
      />
      <Wrapper>
        <ContentTitle>Yard Map</ContentTitle>
        <ContentDescription>
        Managers now have a bird’s eye view of all their workers and vehicles in a dedicated manager’s app. During unloading activity, they can track precisely who’s doing what and take quick action to correct or fine-tune the activities. Managers can forgo the radio and communicate with one or the whole crew via the app, as well as stay on top of maintenance needs.
        </ContentDescription>
      </Wrapper>
    </Content>

    <Content>
      <Wrapper>
        <ContentTitle>Yard Stats</ContentTitle>
        <ContentDescription>
        The app also makes it easier for managers to perform audits and site inspections. For upper management, the main platform provides business analytics that helps them track patterns in site activity and the overall performance of their operations across all their yards. Execs can see metrics the following day rather than waiting until the end of the month to see what happened yesterday. The data is useful to the business in a number of ways, such as informing profitability targets or bids to train companies. Brandon says, “Our data shows train companies how a yard adds even more value
        </ContentDescription>
      </Wrapper>
      <ContentPreview
        src={IMAGES.PROJECT_INTRMODL_YARD_LIST}
        alt="Web preview"
        small
      />
    </Content>

    <Content>
      <ContentPreview
        src={IMAGES.PROJECT_INTRMODL_WORKERS}
        alt="Web preview"
        small
      />
      <Wrapper>
        <ContentTitle>Worker Stats</ContentTitle>
        <ContentDescription>
        Efficiency is measured by job completion speed and workforce costs. Fundamentally, it’s all about how fast the crew can get shipping containers on and off a train, with a number of specialized equipment involved in moving the heavy boxes. The goal is to have the right number of workers doing the right activities at the right moment. Tracking individual worked behavior, good and bad, is helping managers make better more informed decisions.
        <br></br><br></br>
Today, Intrmodl has been fully deployed in six RMS yards, and about 50 others use some aspect of the platform. 

        </ContentDescription>
      </Wrapper>
    </Content>
  </Container>
)

export default ProjectIntrmodl
